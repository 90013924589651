
import { Component, Vue } from 'vue-property-decorator';
import { getPartnerLogos } from '@hokify/shared-components/lib/helpers/logos';

@Component({
	name: 'FooterPartners',
	components: {}
})
export default class FooterPartners extends Vue {
	getPartnerLogos = getPartnerLogos(this.$store.state.topLevelDomain, 'footer');
}
